import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { routes } from '@gss/router';
import { getIsMainProcessInitialized } from '@gss/store/mainProcess/selectors';

export const requireMainProcessInitialized = <
  Props extends Record<string, unknown>
>(
  WrappedComponent: React.ComponentType<Props>
): FC<Props> => {
  const MainProcessInitializedValidator = (props: Props): JSX.Element => {
    const isMainProcessInitialized = useSelector(getIsMainProcessInitialized);

    if (!isMainProcessInitialized) {
      return <Redirect to={routes.WELCOME.path} />;
    }

    return <WrappedComponent {...props} />;
  };

  return MainProcessInitializedValidator;
};
