import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import {
  Body,
  ChargeList,
  Footer,
  Modal,
  ReservationHeader,
  View,
} from 'components';
import { ModalType } from 'components/Modal/Modal';
import { ids } from 'config';
import { compose } from 'redux';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { getTransactions } from 'store/aggregator/selectors';
import {
  addTransaction,
  checkOutFolioSeparately,
  fetchAuthorization,
  fullCheckOut,
  getAddTransactionStatus,
  getUpdateAuthorizationStatus,
  getVoidAuthorizationStatus,
  saveEmailToSendInvoice,
  sendInvoiceEmail,
  updateAuthorization,
  voidAuthorization,
} from 'store/cashiering/actions';
import { fetchFolio } from 'store/cashiering/folio/actions';
import {
  getAddTransactionOperation,
  getCashieringErrors,
  getDetailedFolios,
  getEmailToSendInvoice,
  getFolioAuthorization,
  getFoliosWithCommunicationChannels,
  getPaymentMethods,
  getUpdateAuthorizationOperation,
  getVoidAuthorizationOperation,
  isCashieringFetching,
  isEmailPerFolio,
  isProcessingTransaction,
} from 'store/cashiering/selectors';
import { getIsFastTrackCheckOutProcess } from 'store/checkOutProcess/selectors';
import { saveFolioDocument } from 'store/files/actions';
import { getFolioDocument } from 'store/files/selectors';
import {
  checkEditEmailFormInitialization,
  fetchCommunicationChannels,
  fetchCompany,
  fetchProfile,
  fetchProfilesViews,
} from 'store/profile/actions';
import { getEmail, getEmailAddressIfExist } from 'store/profile/selectors';
import {
  getIdsLinkedToReservation,
  getProfileId,
} from 'store/reservation/selectors';
import { isLoading as isFetching } from 'store/selectors';
import { TransactionSummary } from 'types/Api/Aggregator';
import {
  ChargesFolio,
  FolioWithCommunicationChannels,
  PaymentMethod,
} from 'types/Api/Cashiering';
import { FolioFile } from 'types/Api/Files';
import { ApiError, Operation } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator, Router } from 'utils';
import ChargesListSummary from 'views/CheckOutCharges/ChargesListSummary';

import { Header } from '@gss/components/layout';
import { WithStyles, withStyles } from '@material-ui/styles';

import { setCurrentFolioNumber } from './store/actions';
import { getCurrentFolioNumber } from './store/selectors';
import ChargesHeader from './ChargesHeader';
import styles from './CheckOutCharges.style';
import FolioSummaryModal from './FolioSummaryModal';
import MinibarModal from './MinibarModal';
import PaymentConfirmationScreen from './PaymentConfirmationScreen';
import PaymentResultDrawer from './PaymentResultDrawer';

const { SHOW_CHECK_OUT_SUMMARY } = Configurator.switchCodes;
const { WRONG_AMOUNT } = Configurator.paymentErrorCodes;

interface PassedProps {}

interface CheckOutChargesProps
  extends PassedProps,
    InjectedFormProps,
    RouteComponentProps,
    WithTranslation,
    WithStyles<typeof styles> {
  errors: ApiError[];
  transactions: TransactionSummary[];
  isFetching: boolean;
  folios: ChargesFolio[];
  voidAuthorization: typeof voidAuthorization;
  getVoidAuthorizationStatus: typeof getVoidAuthorizationStatus;
  paymentMethods: PaymentMethod[];
  updateAuthorization: typeof updateAuthorization;
  fetchAuthorization: typeof fetchAuthorization;
  isProcessingTransaction: boolean;
  getAddTransactionStatus: typeof getAddTransactionStatus;
  getUpdateAuthorizationStatus: typeof getUpdateAuthorizationStatus;
  fullCheckOut: typeof fullCheckOut;
  saveFolioDocument: typeof saveFolioDocument;
  addTransaction: typeof addTransaction;
  fetchProfilesViews: typeof fetchProfilesViews;
  transactionOperation: Operation;
  voidAuthorizationOperation: Operation;
  updateAuthorizationOperation: Operation;
  folioFile: FolioFile | undefined;
  isFastTrackCheckOutProcess: boolean;
  isCashieringFetching: boolean;
  businessDate: string;
  email: {
    details: string;
    [index: string]: any;
  };
  fetchProfile: (profileId: string) => void;
  fetchCompany: typeof fetchCompany;
  profileId: string;
  foliosWithCommunicationChannels: FolioWithCommunicationChannels[];
  isEmailPerFolio: (folio: FolioWithCommunicationChannels) => boolean;
  checkOutFolioSeparately: (folio: ChargesFolio) => void;
  sendInvoiceEmail: typeof sendInvoiceEmail;
  emailsToSendInvoice: string[];
  fetchCommunicationChannels: typeof fetchCommunicationChannels;
  saveEmailToSendInvoice: typeof saveEmailToSendInvoice;
  checkEditEmailFormInitialization: typeof checkEditEmailFormInitialization;
  setCurrentFolioNumber: typeof setCurrentFolioNumber;
  fetchFolio: typeof fetchFolio;
  currentFolioNumber: number;
  idsLinkedToReservation: Record<string, string>;
}

interface CheckOutChargesState {
  isSumaryModalOpen: boolean;
  isErrorModalOpen: boolean;
  wasPaymentRetried: boolean;
  isMinibarOpen: boolean;
  errors: string[];
  isEmailModalOpen: boolean;
  wasFoliosSaved: boolean;
  showPaymentConfirmationScreen: boolean;
}

class CheckOutCharges extends PureComponent<
  CheckOutChargesProps,
  CheckOutChargesState
> {
  public state = {
    isSumaryModalOpen: false,
    isErrorModalOpen: false,
    wasPaymentRetried: false,
    isMinibarOpen: false,
    errors: [],
    isEmailModalOpen: false,
    wasFoliosSaved: false,
    showPaymentConfirmationScreen: false,
  };

  public async componentDidMount() {
    const { fetchCommunicationChannels } = this.props;
    await this.resetInvoiceEmails();
    await fetchCommunicationChannels();
    await this.fetchProfilesLinkedToReservation();
    const { folios } = this.props;
    if (folios.length) await this.checkEmailPerFolio();
    this.getEmailFromCurrentFolio();
  }

  public render() {
    const {
      classes,
      t,
      isFetching,
      currentFolioNumber,
      isFastTrackCheckOutProcess,
      folios,
      foliosWithCommunicationChannels,
    } = this.props;
    const {
      isSumaryModalOpen,
      isMinibarOpen,
      isEmailModalOpen,
      showPaymentConfirmationScreen,
    } = this.state;
    const folioWithCommunicationChannels =
      foliosWithCommunicationChannels[currentFolioNumber];
    const currentFolio = folios[currentFolioNumber];
    const shouldSkipPayment =
      currentFolio && !currentFolio.remoteCheckOutAllowed;
    const transactions = currentFolio?.transactions || [];
    const amount = currentFolio ? currentFolio.grossBalance.amount : 0;
    const communicationChannels =
      folioWithCommunicationChannels?.communicationChannels;
    const isFirstFolio = currentFolioNumber === 0;

    return !folios.length ? (
      <Redirect to={Router.nextStepURL} />
    ) : (
      <View modal={this.getModalSettings()} idle={{ type: 'modal' }}>
        <MinibarModal
          isOpen={isMinibarOpen}
          onSubmit={this.toggleMinibarModal}
          onClose={this.toggleMinibarModal}
          folio={currentFolio}
        />
        <Modal
          isOpen={isEmailModalOpen}
          type="missingEmail"
          currentFolioNumber={currentFolioNumber}
          onSubmit={this.closeEmailPopup}
        />
        <Header title={`${t('CHECK_OUT')} - ${t('REVIEW_CHARGES')}`} />
        <ReservationHeader />
        {shouldSkipPayment ? <PaymentResultDrawer isOpen type="skip" /> : null}
        {showPaymentConfirmationScreen || isFastTrackCheckOutProcess ? (
          <PaymentConfirmationScreen
            folios={folios}
            withPaymentResult={!isFastTrackCheckOutProcess}
            email={this.getEmailFromCurrentFolio()}
            onConfirmationScreenContinue={this.onConfirmationScreenContinue}
            currentFolioNumber={currentFolioNumber}
            communicationChannels={communicationChannels}
          />
        ) : (
          <>
            <Body className={classes.body}>
              <ChargesHeader
                currentFolioNumber={currentFolioNumber}
                folios={folios}
                isProcessing={isFetching}
                onClick={this.toggleMinibarModal}
                disableMinibar={isFetching}
                communicationChannels={communicationChannels}
                email={this.getEmailFromCurrentFolio()}
              />
              {!isFetching || shouldSkipPayment ? (
                <ChargeList
                  hideCurrency={isSumaryModalOpen}
                  transactions={transactions}
                />
              ) : null}
              <FolioSummaryModal
                hideCurrency={isSumaryModalOpen}
                transactions={transactions}
                isOpen={isSumaryModalOpen}
                folioBalance={amount}
                onCancel={this.toggleSummaryModal}
                onSubmit={this.submit}
                folio={currentFolio}
              />
            </Body>
            <ChargesListSummary
              folioBalance={amount}
              className={classes.chargesSummary}
            />
            <Footer
              onContinue={this.onContinue}
              onGoBack={this.onGoBack}
              isOnContinuePrior
              hasCancelButton
              hasContinueButton
              hasBackButton={!isFirstFolio}
              routeName={t('CHECK_OUT')}
              continueButtonLabel={t('CONFIRM')}
            />
          </>
        )}
      </View>
    );
  }

  private getEmailFromCurrentFolio = () => {
    const { foliosWithCommunicationChannels, currentFolioNumber } = this.props;
    const folioWithCommunicationChannels =
      foliosWithCommunicationChannels[currentFolioNumber];
    const communicationChannels =
      folioWithCommunicationChannels?.communicationChannels;
    const {
      entityCodes: { BILLING_EMAIL },
      emailTypes,
    } = Configurator;
    const billingEmailType =
      Configurator.getEntityTypeCode(emailTypes, BILLING_EMAIL) || '';
    const email =
      getEmailAddressIfExist(communicationChannels, billingEmailType) || '';

    return email;
  };

  private onContinue = () => {
    const { folios, currentFolioNumber } = this.props;
    const currentFolio = folios[currentFolioNumber];
    const { isRecentlySettled, remoteCheckOutAllowed } = currentFolio;
    if (isRecentlySettled || !remoteCheckOutAllowed)
      return this.goToNextFolioStep();
    const shouldOpenSummary = Configurator.getSwitch(SHOW_CHECK_OUT_SUMMARY);
    if (shouldOpenSummary) return this.toggleSummaryModal();

    return this.submit();
  };

  private onGoBack = () => {
    const { history, currentFolioNumber } = this.props;
    const isFolioFirst = !currentFolioNumber;

    return isFolioFirst
      ? history.replace(Router.prevStepURL)
      : this.goToPrevStep();
  };

  private submit = async () => {
    const shouldOpenSummary = Configurator.getSwitch(SHOW_CHECK_OUT_SUMMARY);
    const { folios, currentFolioNumber } = this.props;
    const currentFolio = folios[currentFolioNumber];
    if (shouldOpenSummary) {
      await this.toggleSummaryModal();
      const { folioFile, saveFolioDocument } = this.props;
      if (folioFile) {
        await saveFolioDocument(folioFile.file, folioFile.id);
      }
    }

    return currentFolio.remoteCheckOutAllowed
      ? this.handleSettlement()
      : this.goToNextFolioStep();
  };

  private toggleSummaryModal = () => {
    this.setState(({ isSumaryModalOpen }) => ({
      isSumaryModalOpen: !isSumaryModalOpen,
    }));
  };

  private changeRoute = () => {
    const { history } = this.props;
    history.replace(Router.nextStepURL);
  };

  private toggleMinibarModal = () => {
    const { isMinibarOpen } = this.state;
    this.setState({ isMinibarOpen: !isMinibarOpen });
  };

  private getModalSettings = () => {
    const {
      errors: stateErrors,
      wasPaymentRetried,
      showPaymentConfirmationScreen,
    } = this.state;
    const {
      isFastTrackCheckOutProcess,
      isCashieringFetching,
      isFetching,
      folios,
      currentFolioNumber,
      errors,
      t,
    } = this.props;
    const {
      operationStatuses: { PAYMENT_FAILURE },
    } = Configurator;
    const currentFolio = folios[currentFolioNumber];
    const { remoteCheckOutAllowed } = currentFolio;
    const isWrongAmountError = errors.find(
      (item) => item.code === WRONG_AMOUNT
    );
    const isPaymentFailed = stateErrors.find(
      (item) => item === PAYMENT_FAILURE
    );

    if (isFastTrackCheckOutProcess) {
      return {
        isLoading: isCashieringFetching,
      };
    }

    const disableLoader =
      remoteCheckOutAllowed && !showPaymentConfirmationScreen;
    if (isWrongAmountError) {
      return {
        disableLoader,
        values: [...errors, ...stateErrors],
        type: 'error' as ModalType,
        defaultError: t('QUANTITY_EXCEEDS_MAXIMUM'),
        shouldRedirect: false,
        clearErrors: true,
      };
    }

    const errorArray = [...errors, ...stateErrors];

    if (isFetching && !errorArray.length) {
      return {
        isLoading: true,
      };
    }

    return {
      disableLoader,
      values: errorArray,
      type: (isPaymentFailed ? 'payment' : 'error') as ModalType,
      onClick: this.handleRetry,
      clearErrors: true,
      shouldRedirect: showPaymentConfirmationScreen || wasPaymentRetried,
    };
  };

  private settleFolio = async () => {
    const {
      folios,
      getAddTransactionStatus,
      addTransaction,
      paymentMethods,
      voidAuthorization,
      getVoidAuthorizationStatus,
      currentFolioNumber,
    } = this.props;
    const currentFolio = folios[currentFolioNumber];
    const {
      grossBalance,
      grossBalance: { amount },
      id: folioId,
      version,
    } = currentFolio;
    if (!amount) return this.handlePaymentSuccess();
    const {
      operationStatuses: { PAYMENT_SUCCESS },
    } = Configurator;
    const authorization = getFolioAuthorization(folioId, paymentMethods);
    // const isAuthorizationTooSmall = authorizationAmount && authorizationAmount < amount;
    // if (isAuthorizationTooSmall && Configurator.isTopUpAllowed) {
    //   const authorization = getFolioAuthorization(folioId, paymentMethods);
    //   await fetchAuthorization(authorization);
    //   await updateAuthorization(authorization, `${amount}`);
    //   await getUpdateAuthorizationStatus();
    // }
    // const { updateAuthorizationOperation: { status: updateAuthorizationStatus } } = this.props;
    // if (updateAuthorizationStatus && updateAuthorizationStatus === PAYMENT_FAILURE) {
    //   return this.handlePaymentFailure();
    // }
    const { transactionCode } = Configurator.paymentInterface;
    const transaction = {
      transactionCode,
      foreignUnitPrice: null,
      isMainTransaction: true,
      quantity: 1,
      unitPrice: grossBalance,
    };

    const pendingTransaction = {
      businessDate: Configurator.propertyBusinessDate,
      id: `${folioId}-payment`,
      transactionGroupTypeCode: Configurator.transactionCodes.PAYMENT,
      grossAmount: {
        amount: grossBalance.amount,
      },
      grossUnitPrice: {
        amount: grossBalance.amount,
      },
      transactionCode: {
        description:
          Configurator.getTransactionCodeDescription(transactionCode),
      },
    };

    const pendingData = {
      folioId,
      pendingTransaction,
    };

    const authorizationFolioData =
      authorization && Configurator.isTopUpAllowed
        ? {
            creditCardAuthorizationId: authorization.id,
          }
        : {};
    const folioData = {
      folioId,
      version,
      ...authorizationFolioData,
    };

    await addTransaction(folioData, transaction);

    const { errors } = this.props;
    if (!errors.length) {
      await getAddTransactionStatus(pendingData);
    }

    const {
      transactionOperation: { status },
    } = this.props;
    // if (Configurator.isTopUpAllowed) {
    //   return status === PAYMENT_SUCCESS
    //    ? this.handlePaymentSuccess()
    //    : this.handlePaymentFailure();
    // }
    if (status !== PAYMENT_SUCCESS) return this.handlePaymentFailure();
    if (status === PAYMENT_SUCCESS && Configurator.isTopUpAllowed) {
      return this.handlePaymentSuccess(folioData.folioId);
    }
    if (!Configurator.isTopUpAllowed && authorization) {
      await voidAuthorization(authorization);
      await getVoidAuthorizationStatus();
      const {
        voidAuthorizationOperation: { status: voidStatus },
      } = this.props;

      return voidStatus === PAYMENT_SUCCESS
        ? this.handlePaymentSuccess(folioData.folioId)
        : this.handlePaymentFailure();
    }
  };

  private goToNextStep = () => {
    const { currentFolioNumber, setCurrentFolioNumber } = this.props;
    setCurrentFolioNumber(currentFolioNumber + 1);
  };

  private goToPrevStep = () => {
    const { currentFolioNumber, setCurrentFolioNumber } = this.props;
    setCurrentFolioNumber(currentFolioNumber - 1);
  };

  private handlePaymentSuccess = async (folioId?: string) => {
    const { fetchFolio } = this.props;
    if (folioId) await fetchFolio(folioId);
    this.setState({ showPaymentConfirmationScreen: true });
  };

  private goToNextFolioStep = () => {
    const { currentFolioNumber } = this.props;
    const { folios } = this.props;
    const isFolioLast = currentFolioNumber + 1 === folios.length;
    if (isFolioLast) return this.onSettlementSuccess();
    this.setState({ wasPaymentRetried: false });
    this.goToNextStep();
  };

  private handlePaymentFailure = () => {
    const {
      operationStatuses: { PAYMENT_FAILURE },
    } = Configurator;
    this.setState({ errors: [PAYMENT_FAILURE] });
  };

  private handleSettlement = () => {
    return this.settleFolio();
  };

  private handleRetry = () => {
    this.setState({ errors: [], wasPaymentRetried: true });
  };

  private onSettlementSuccess = async () => {
    const { fullCheckOut } = this.props;
    if (!Router.isCurrentStepLast) return this.changeRoute();
    await fullCheckOut();
    this.changeRoute();
  };

  private closeEmailPopup = () => this.setState({ isEmailModalOpen: false });

  private checkEmailPerFolio = () => {
    const { profileId, foliosWithCommunicationChannels, currentFolioNumber } =
      this.props;
    const currentFolio = foliosWithCommunicationChannels[currentFolioNumber];
    const { profileId: currentFolioProfileId } = currentFolio;
    const isGuestFolio = currentFolioProfileId === profileId;
    const hasFolioEmail = isGuestFolio || isEmailPerFolio(currentFolio);
    this.setState({ isEmailModalOpen: Boolean(!hasFolioEmail) });
  };

  private sendInvoiceToFolioEmails = async () => {
    const {
      emailsToSendInvoice,
      sendInvoiceEmail,
      folios,
      checkEditEmailFormInitialization,
      currentFolioNumber,
    } = this.props;
    const currentFolio = folios[currentFolioNumber];
    const { id: folioId } = currentFolio;
    const email = this.getEmailFromCurrentFolio();
    !emailsToSendInvoice.length && email && emailsToSendInvoice.push(email);
    await sendInvoiceEmail(folioId, emailsToSendInvoice);
    checkEditEmailFormInitialization(false);
  };

  private resetInvoiceEmails = async () => {
    const { saveEmailToSendInvoice } = this.props;
    await saveEmailToSendInvoice([]);
  };

  private onConfirmationScreenContinue = async () => {
    const {
      checkOutFolioSeparately,
      currentFolioNumber,
      isFastTrackCheckOutProcess,
    } = this.props;
    const { folios } = this.props;
    const currentFolio = folios[currentFolioNumber];
    const isFolioLast = currentFolioNumber + 1 === folios.length;

    await checkOutFolioSeparately(currentFolio);
    await this.sendInvoiceToFolioEmails();
    const { errors } = this.props;
    if (!isFastTrackCheckOutProcess && errors.length) return;
    if (isFolioLast) return this.onSettlementSuccess();
    this.goToNextStep();
    await this.resetInvoiceEmails();
    this.setState({
      wasPaymentRetried: false,
      showPaymentConfirmationScreen: false,
    });
    if (!isFolioLast) await this.checkEmailPerFolio();
  };

  private fetchProfilesLinkedToReservation = async () => {
    const { fetchProfilesViews, idsLinkedToReservation } = this.props;
    const linkedIds: string[] = Object.values(idsLinkedToReservation);
    const ids: string[] = linkedIds.filter((id: string) => id);
    ids.length && (await Promise.resolve(fetchProfilesViews(ids)));
  };
}

const mapStateToProps = (state: Store) => ({
  email: getEmail(state),
  errors: getCashieringErrors(state),
  transactions: getTransactions(state),
  folios: getDetailedFolios(state),
  isProcessingTransaction: isProcessingTransaction(state),
  isFetching: isFetching(state),
  transactionOperation: getAddTransactionOperation(state),
  paymentMethods: getPaymentMethods(state),
  voidAuthorizationOperation: getVoidAuthorizationOperation(state),
  updateAuthorizationOperation: getUpdateAuthorizationOperation(state),
  folioFile: getFolioDocument(state),
  profileId: getProfileId(state),
  foliosWithCommunicationChannels: getFoliosWithCommunicationChannels(state),
  emailsToSendInvoice: getEmailToSendInvoice(state),
  currentFolioNumber: getCurrentFolioNumber(state),
  isFastTrackCheckOutProcess: getIsFastTrackCheckOutProcess(state),
  isCashieringFetching: isCashieringFetching(state),
  idsLinkedToReservation: getIdsLinkedToReservation(state),
});

const mapDispatchToProps = {
  getAddTransactionStatus,
  voidAuthorization,
  getVoidAuthorizationStatus,
  addTransaction,
  fullCheckOut,
  updateAuthorization,
  getUpdateAuthorizationStatus,
  fetchAuthorization,
  saveFolioDocument,
  fetchProfile,
  fetchCompany,
  checkOutFolioSeparately,
  sendInvoiceEmail,
  fetchCommunicationChannels,
  saveEmailToSendInvoice,
  checkEditEmailFormInitialization,
  setCurrentFolioNumber,
  fetchFolio,
  fetchProfilesViews,
};

export default compose(
  withRouter,
  withTranslation(),
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: ids.EDIT_EMAIL_FORM,
  })
)(CheckOutCharges) as (props: PassedProps) => JSX.Element;
