import React, { PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Footer, ReservationHeader, View } from 'components';
import {
  ProfileDuplicatesModal,
  ProfileDuplicateVariants,
} from 'components/modals';
import isEqual from 'lodash.isequal';
import { compose } from 'redux';
import { resetAppProgress } from 'store/actions';
import { sendUpdateProfileData } from 'store/checkInProcess/actions';
import { getIsCheckInProcessFetching } from 'store/checkInProcess/selectors';
import {
  checkIndividualProfileMatching,
  fetchProfile,
  setIsGuestAcceptedProfileDuplicateRisk,
} from 'store/profile/actions';
import {
  getEmail,
  getIfGuestAcceptedProfileDuplicateRisk,
  getIsProfileDuplicateDetected,
  getMobile,
  getProfile,
  getProfileAddress,
} from 'store/profile/selectors';
import { getErrors } from 'store/selectors';
import { getFeatureToggles, getIsDistrictEnabled } from 'store/setup/selectors';
import { Address, CommunicationChannel, Profile } from 'types/Api/Profile';
import { ApiError } from 'types/Api/Shared';
import Store from 'types/Store';
import { StoredFeatureToggles } from 'types/Store/SetupStore';
import { Configurator, Router } from 'utils';
import { Path } from 'utils/Router';
import AddressSubForm from 'views/CheckInCommunicationDetails/AddressSubForm';

import {
  Body,
  Section,
  Text,
  TextSize,
  TextWeight,
} from '@ac/kiosk-components';
import { isDefined } from '@ac/library-utils/dist/utils';
import { Form, getChanges } from '@ac/react-infrastructure';

import {
  KioskCheckInUpdateAddressDetails,
  KioskCheckInUpdateAddresses,
  KioskCheckInUpdatePersonalDetails,
  KioskCheckInUpdateProfile,
} from '@gss/api/KioskApi/entries';
import { Header } from '@gss/components/layout';
import { fetchStates } from '@gss/store/lazyLoadedDictionary/actions';
import { submitFormById } from '@gss/utils/form';

import {
  cacheCommunicationDetailsFormData,
  setScannedAddressStatus,
  setUpdateCommunicationDetailsPayload,
} from './store/actions';
import { ScannedAddressStatus } from './store/interfaces';
import {
  getCachedCommunicationDetailsFormData,
  getIdOfPreviouslyAddedAddress,
  getScannedAddress,
  getScannedAddressStatus,
} from './store/selectors';
import AdditionalInfoForm from './AdditionalInfoForm';
import ContactForm from './ContactForm';
import { ReviewAddressModal } from './ReviewAddressModal';
import {
  AdditionalInfoFormValues,
  AddressSubFormValues,
  CheckInCommunicationDetailsFormValues,
  CommunicationDetailsFormSection,
  ContactFormValues,
} from './types';

const CHECK_IN_COMMUNICATION_DETAILS_FORM_ID =
  'check-in-communication-details-form-id';
const { CHECK_IN_FAILED_MESSAGE } = Configurator.getTranslationCodes();
const {
  SHOW_ADDRESS,
  SHOW_CONTACT,
  SHOW_NATIONALITY_FIELD,
  ALLOW_KIOSK_PROFILE_MATCH,
  ALLOW_KIOSK_PROFILE_DUPLICATES,
  DISTRICT,
  SHOW_TAX_ID,
} = Configurator.switchCodes;

interface PassedProps {}

interface CheckInCommunicationDetailsProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation {
  checkIndividualProfileMatching: typeof checkIndividualProfileMatching;
  resetAppProgress: typeof resetAppProgress;
  setIsGuestAcceptedProfileDuplicateRisk: typeof setIsGuestAcceptedProfileDuplicateRisk;
  setUpdateCommunicationDetailsPayload: typeof setUpdateCommunicationDetailsPayload;
  cacheCommunicationDetailsFormData: typeof cacheCommunicationDetailsFormData;
  setScannedAddressStatus: typeof setScannedAddressStatus;
  sendUpdateProfileData: typeof sendUpdateProfileData;
  fetchStates: typeof fetchStates.trigger;
  fetchProfile: typeof fetchProfile;
  errors: Array<Error | ApiError>;
  profile: Profile;
  address?: Address;
  mobile?: CommunicationChannel;
  email?: CommunicationChannel;
  previouslyAddedAddressId?: string;
  isProfileDuplicateDetected: boolean;
  isGuestAcceptedProfileDuplicateRisk: boolean;
  isCheckInProcessFetching: boolean;
  scannedAddress?: Partial<Address>;
  scannedAddressStatus?: ScannedAddressStatus;
  cachedCommunicationDetailsFormData?: CheckInCommunicationDetailsFormValues;
  featureToggles: StoredFeatureToggles | undefined;
  isDistrictEnabled: boolean;
}

interface CheckInCommunicationDetailsStore {
  isFormValid: boolean;
  isViewReady: boolean;
  initialFormValues: CheckInCommunicationDetailsFormValues;
}

class CheckInCommunicationDetails extends PureComponent<
  CheckInCommunicationDetailsProps,
  CheckInCommunicationDetailsStore
> {
  public state: CheckInCommunicationDetailsStore = {
    isFormValid: true,
    initialFormValues: {
      contact: {},
      mainAddress: {},
    },
    isViewReady: false,
  };

  async componentDidMount() {
    const { fetchStates, address, scannedAddress, scannedAddressStatus, i18n } =
      this.props;

    const countries = [
      address?.countryCode,
      scannedAddress?.countryCode,
    ].filter(isDefined);

    for (const country of countries) {
      fetchStates({ countryCode: country, languageCode: i18n.language });
    }

    if (!scannedAddressStatus && !address) {
      this.addScannedAddressAsMain();
    }

    this.setState({
      isViewReady: true,
      initialFormValues: this.getInitialFormValues(),
    });
  }

  async componentDidUpdate(prevProps: CheckInCommunicationDetailsProps) {
    const { scannedAddressStatus: prevStatus } = prevProps;
    const { scannedAddressStatus } = this.props;

    if (prevStatus !== scannedAddressStatus) {
      this.setState({
        initialFormValues: this.getInitialFormValues(),
      });
    }
  }

  public render() {
    const {
      t,
      address,
      errors,
      isProfileDuplicateDetected,
      isGuestAcceptedProfileDuplicateRisk,
      scannedAddress,
      scannedAddressStatus,
      isCheckInProcessFetching,
      isDistrictEnabled,
    } = this.props;
    const { initialFormValues, isViewReady } = this.state;

    return (
      <View
        modal={{
          values: errors,
          customErrorCode: CHECK_IN_FAILED_MESSAGE,
          isLoading: !isViewReady,
        }}
        idle={{ type: 'modal' }}
      >
        {isProfileDuplicateDetected && !isGuestAcceptedProfileDuplicateRisk && (
          <ProfileDuplicatesModal
            variant={this.profileDuplicatesModalVariant}
            onCancel={this.handleProfileDuplicateModalCancel}
            onSubmit={this.handleProfileDuplicateModalSubmit}
          />
        )}

        {isViewReady &&
          Boolean(address && scannedAddress && !scannedAddressStatus) && (
            <ReviewAddressModal
              mainAddress={address!}
              scannedAddress={scannedAddress!}
              onAddAdditional={this.handleAddAsAdditional}
              onReplace={this.handleReplace}
              onSkipClick={this.handleReviewAddressSkipClick}
            />
          )}

        <Header title={`${t('CHECK_IN')} - ${t('COMMUNICATION_DETAILS')}`} />
        <ReservationHeader />

        <Form
          initialValues={initialFormValues}
          onSubmit={this.handleSubmit}
          keepDirtyOnReinitialize
        >
          {(formRenderProps) => (
            <>
              <Body>
                <Section className="spacing-top-sm spacing-bottom-sm">
                  <Text size={TextSize.xlg} weight={TextWeight.light}>
                    {t('COMPLETE_THE_FORM')}
                  </Text>
                  <Text hint className="spacing-top-sm spacing-bottom-xxlg">
                    {t('ALL_FIELDS_MANDATORY')}
                  </Text>

                  <form
                    id={CHECK_IN_COMMUNICATION_DETAILS_FORM_ID}
                    onSubmit={formRenderProps.handleSubmit}
                  >
                    {Configurator.getSwitch(SHOW_CONTACT) && (
                      <ContactForm
                        formRenderProps={formRenderProps}
                        nestedPropertyName={
                          CommunicationDetailsFormSection.contact
                        }
                      />
                    )}
                    {Configurator.getSwitch(SHOW_ADDRESS) && (
                      <>
                        <AddressSubForm
                          className="spacing-top-lg"
                          formRenderProps={formRenderProps}
                          isDistrictFieldEnabled={isDistrictEnabled}
                          nestedPropertyName={
                            CommunicationDetailsFormSection.mainAddress
                          }
                          header={
                            scannedAddressStatus ===
                            ScannedAddressStatus.addedAsAdditional
                              ? t('CHECK_IN_COMMUNICATION_DETAILS.MAIN_ADDRESS')
                              : t('ADDRESS')
                          }
                        />

                        {scannedAddressStatus ===
                          ScannedAddressStatus.addedAsAdditional && (
                          <AddressSubForm
                            className="spacing-top-lg"
                            formRenderProps={formRenderProps}
                            isDistrictFieldEnabled={isDistrictEnabled}
                            nestedPropertyName={
                              CommunicationDetailsFormSection.scannedAddress
                            }
                            header={t(
                              'CHECK_IN_COMMUNICATION_DETAILS.SCANNED_ADDRESS'
                            )}
                          />
                        )}
                      </>
                    )}
                    {(this.isNationalityEnabled || this.isTaxIDEnabled) && (
                      <AdditionalInfoForm
                        className="spacing-top-lg"
                        nestedPropertyName={
                          CommunicationDetailsFormSection.additionalData
                        }
                        isNationalityEnabled={this.isNationalityEnabled}
                        isTaxIDEnabled={this.isTaxIDEnabled}
                      />
                    )}
                  </form>
                </Section>
              </Body>
              <Footer
                hasBackButton={this.isBackButtonVisible}
                hasCancelButton
                hasContinueButton
                routeName={t('CHECK_IN')}
                onContinue={submitFormById(
                  CHECK_IN_COMMUNICATION_DETAILS_FORM_ID
                )}
                onGoBack={this.goBack}
                isContinueDisabled={
                  !formRenderProps.valid || isCheckInProcessFetching
                }
              />
            </>
          )}
        </Form>
      </View>
    );
  }

  private get isNationalityEnabled() {
    return Configurator.getSwitch(SHOW_NATIONALITY_FIELD);
  }

  private get isTaxIDEnabled() {
    const { featureToggles } = this.props;

    return Configurator.getSwitch(SHOW_TAX_ID);
  }

  private get isBackButtonVisible() {
    const authStepPath = Router.steps[Path.checkIn].AUTH?.url;

    return Router.prevStepURL !== authStepPath;
  }

  private get profileDuplicatesModalVariant() {
    return Configurator.getSwitch(ALLOW_KIOSK_PROFILE_DUPLICATES)
      ? ProfileDuplicateVariants.duplicatesWarning
      : ProfileDuplicateVariants.duplicatesError;
  }

  private getInitialFormValues(): CheckInCommunicationDetailsFormValues {
    const {
      isDistrictEnabled,
      mobile,
      email,
      address,
      profile,
      scannedAddressStatus,
      scannedAddress,
      cachedCommunicationDetailsFormData,
    } = this.props;

    if (cachedCommunicationDetailsFormData) {
      return cachedCommunicationDetailsFormData;
    }

    const contact = {
      mobile: mobile?.details,
      email: email?.details,
    };

    const mainAddress =
      scannedAddressStatus === ScannedAddressStatus.replaced ||
      scannedAddressStatus === ScannedAddressStatus.addedAsMain
        ? scannedAddress
        : {
            addressLine1: address?.addressLine1,
            addressLine2: address?.addressLine2,
            city: address?.city,
            postCode: address?.postCode,
            stateCode: address?.stateCode,
            countryCode: address?.countryCode,
            districtId: isDistrictEnabled ? address?.districtId : undefined,
          };

    const additionalData = {
      nationalityCode: this.isNationalityEnabled
        ? profile?.details?.nationalityCode
        : undefined,
      taxId: this.isTaxIDEnabled ? profile?.details?.taxId : undefined,
    };

    return {
      [CommunicationDetailsFormSection.contact]: { ...contact },
      [CommunicationDetailsFormSection.mainAddress]: mainAddress || {},
      ...(scannedAddressStatus === ScannedAddressStatus.addedAsAdditional
        ? { [CommunicationDetailsFormSection.scannedAddress]: scannedAddress }
        : {}),
      [CommunicationDetailsFormSection.additionalData]: additionalData,
    };
  }

  private handleReviewAddressSkipClick = () => {
    const { setScannedAddressStatus } = this.props;
    setScannedAddressStatus(ScannedAddressStatus.skipped);
  };

  private handleAddAsAdditional = () => {
    const { setScannedAddressStatus } = this.props;
    setScannedAddressStatus(ScannedAddressStatus.addedAsAdditional);
  };

  private handleReplace = () => {
    const { setScannedAddressStatus } = this.props;
    setScannedAddressStatus(ScannedAddressStatus.replaced);
  };

  private addScannedAddressAsMain = () => {
    const { setScannedAddressStatus } = this.props;
    setScannedAddressStatus(ScannedAddressStatus.addedAsMain);
  };

  private handleSubmit = async (
    values: CheckInCommunicationDetailsFormValues
  ) => {
    const {
      setUpdateCommunicationDetailsPayload,
      cacheCommunicationDetailsFormData,
      sendUpdateProfileData,
      fetchProfile,
      profile,
      history,
    } = this.props;

    await fetchProfile(profile.id);

    const {
      [CommunicationDetailsFormSection.contact]: contactDetails,
      [CommunicationDetailsFormSection.mainAddress]: mainAddressDetails,
      [CommunicationDetailsFormSection.scannedAddress]: scannedAddressDetails,
      [CommunicationDetailsFormSection.additionalData]: additionalDataDetails,
    } = values;

    const contactDetailsPayload = this.prepareContactDetails(contactDetails);
    const addresses = this.prepareUpdateAddressesPayload(
      mainAddressDetails,
      scannedAddressDetails
    );
    const personalDetails = this.preparePersonalDetails(additionalDataDetails);

    setUpdateCommunicationDetailsPayload({
      ...contactDetailsPayload,
      ...(addresses ? { addresses } : undefined),
      ...(personalDetails ? { personalDetails } : undefined),
    });

    cacheCommunicationDetailsFormData(values);

    await sendUpdateProfileData();

    const { errors } = this.props;
    if (errors?.length) {
      return;
    }

    if (!Configurator.getSwitch(ALLOW_KIOSK_PROFILE_MATCH)) {
      return history.push(Router.nextStepURL);
    }

    const isDuplicateDetected = await this.checkProfileDuplicates();
    if (!isDuplicateDetected) history.push(Router.nextStepURL);
  };

  private prepareContactDetails = (
    contact: ContactFormValues
  ):
    | Pick<KioskCheckInUpdateProfile, 'primaryEmail' | 'primaryMobile'>
    | undefined => {
    if (!Configurator.getSwitch(SHOW_CONTACT)) return undefined;

    const { email, mobile, i18n } = this.props;
    const { initialFormValues } = this.state;

    if (email && mobile && isEqual(contact, initialFormValues.contact)) {
      return undefined;
    }

    const telephonePrefixes = Configurator.getMobilePrefixes(i18n.language);
    const emailTypeCode =
      email?.typeCode || Configurator.defaultCommunicationEmailType?.code;
    const mobileTypeCode =
      mobile?.typeCode || Configurator.defaultCommunicationMobileType?.code;

    const emailTypeId = Configurator.emailTypes.find(
      ({ code }) => code === emailTypeCode
    )?.id;

    const mobileTypeId = Configurator.mobileTypes.find(
      ({ code }) => code === mobileTypeCode
    )?.id;

    const prefix = telephonePrefixes.find(
      (singlePrefix) => singlePrefix.code === contact.prefix
    )?.prefix;

    return {
      ...(emailTypeId && {
        primaryEmail: {
          typeId: emailTypeId,
          details: contact.email,
        },
      }),
      ...(mobileTypeId && {
        primaryMobile: {
          typeId: mobileTypeId,
          details: [prefix, contact.mobile].join(''),
        },
      }),
    };
  };

  private preparePersonalDetails = (
    additionalData?: AdditionalInfoFormValues
  ): KioskCheckInUpdatePersonalDetails | undefined => {
    const { initialFormValues } = this.state;
    const initialAdditionalData = initialFormValues.additionalData;

    const personalDetailsChanges = getChanges(
      initialAdditionalData,
      additionalData
    );
    const arePersonalDetailsUnchanged =
      !personalDetailsChanges || !Object.keys(personalDetailsChanges).length;

    if (arePersonalDetailsUnchanged) {
      return undefined;
    }

    return Object.fromEntries(
      Object.entries(personalDetailsChanges).map(([key, value]) => {
        return [key, value || null];
      })
    );
  };

  private prepareUpdateAddressesPayload = (
    mainAddress: AddressSubFormValues,
    scannedAddress?: AddressSubFormValues
  ): KioskCheckInUpdateAddresses | undefined => {
    if (!Configurator.getSwitch(SHOW_ADDRESS)) return undefined;

    const { previouslyAddedAddressId, scannedAddressStatus, address } =
      this.props;
    const { initialFormValues } = this.state;

    const updateAddressesPayload: KioskCheckInUpdateAddresses = {
      update: [],
      add: [],
    };

    const isMainAddressReplaced =
      scannedAddressStatus === ScannedAddressStatus.replaced;
    const isScanAddressAddedAdditionally =
      scannedAddressStatus === ScannedAddressStatus.addedAsAdditional;

    const defaultAddressTypeCode = Configurator.defaultCompanyAddressType?.code;
    const defaultAddressTypeId = Configurator.addressTypes.find(
      ({ code }) => code === defaultAddressTypeCode
    )?.id;
    const currentMainAddressTypeId = Configurator.addressTypes.find(
      ({ code }) => code === address?.typeCode
    )?.id;

    if (!defaultAddressTypeId) {
      return undefined;
    }

    const addressData: KioskCheckInUpdateAddressDetails = {
      ...this.getBasicAddressData(mainAddress),
      primary: true,
      id: isMainAddressReplaced ? previouslyAddedAddressId : address?.id,
      typeId: isMainAddressReplaced
        ? defaultAddressTypeId
        : currentMainAddressTypeId || defaultAddressTypeId,
    };

    if (!addressData.id) {
      updateAddressesPayload.add.push(addressData);

      if (
        isMainAddressReplaced &&
        !previouslyAddedAddressId &&
        address?.id &&
        currentMainAddressTypeId
      ) {
        updateAddressesPayload.update.push({
          ...this.getBasicAddressData(address),
          primary: false,
          typeId: currentMainAddressTypeId,
        });
      }
    } else if (
      addressData.id &&
      !isEqual(mainAddress, initialFormValues.mainAddress)
    ) {
      updateAddressesPayload.update.push(addressData);
    }

    if (isScanAddressAddedAdditionally && scannedAddress) {
      const addressData: KioskCheckInUpdateAddressDetails = {
        ...this.getBasicAddressData(scannedAddress),
        primary: false,
        id: previouslyAddedAddressId,
        typeId: defaultAddressTypeId,
      };

      if (!addressData.id) {
        updateAddressesPayload.add.push(addressData);
      } else if (
        addressData.id &&
        !isEqual(scannedAddress, initialFormValues.scannedAddress)
      ) {
        updateAddressesPayload.update.push(addressData);
      }
    }

    return !updateAddressesPayload?.add.length &&
      !updateAddressesPayload.update.length
      ? undefined
      : updateAddressesPayload;
  };

  private getBasicAddressData = (data: Partial<Address>) => {
    const { i18n } = this.props;

    return {
      id: data.id,
      addressLine1: data.addressLine1,
      addressLine2: data.addressLine2,
      city: data.city,
      languageCode: data.languageCode || i18n.language.toUpperCase(),
      countryCode: data.countryCode,
      postalCode: data.postCode,
      stateCode: data.stateCode,
      districtId: data.districtId,
    };
  };

  private goBack = () => {
    const { history } = this.props;
    history.push(Router.prevStepURL);
  };

  private handleProfileDuplicateModalCancel = () => {
    const { history, resetAppProgress } = this.props;
    resetAppProgress(history);
  };

  private handleProfileDuplicateModalSubmit = () => {
    const { history, setIsGuestAcceptedProfileDuplicateRisk } = this.props;
    history.push(Router.nextStepURL);
    setIsGuestAcceptedProfileDuplicateRisk(true);
  };

  private checkProfileDuplicates = async () => {
    const {
      checkIndividualProfileMatching,
      profile,
      isProfileDuplicateDetected,
      isGuestAcceptedProfileDuplicateRisk,
    } = this.props;

    if (isProfileDuplicateDetected && isGuestAcceptedProfileDuplicateRisk)
      return false;
    await checkIndividualProfileMatching(profile.id);

    const { isProfileDuplicateDetected: updatedIsProfileDuplicateDetected } =
      this.props;

    return (
      updatedIsProfileDuplicateDetected && !isGuestAcceptedProfileDuplicateRisk
    );
  };
}

const mapStateToProps = (state: Store) => ({
  errors: getErrors(state),
  profile: getProfile(state),
  email: getEmail(state),
  mobile: getMobile(state),
  address: getProfileAddress(state),
  isProfileDuplicateDetected: getIsProfileDuplicateDetected(state),
  isGuestAcceptedProfileDuplicateRisk:
    getIfGuestAcceptedProfileDuplicateRisk(state),
  scannedAddress: getScannedAddress(state),
  scannedAddressStatus: getScannedAddressStatus(state),
  cachedCommunicationDetailsFormData:
    getCachedCommunicationDetailsFormData(state),
  previouslyAddedAddressId: getIdOfPreviouslyAddedAddress(state),
  isCheckInProcessFetching: getIsCheckInProcessFetching(state),
  featureToggles: getFeatureToggles(state),
  isDistrictEnabled: getIsDistrictEnabled(state),
});

const mapDispatchToProps = {
  checkIndividualProfileMatching,
  resetAppProgress,
  setIsGuestAcceptedProfileDuplicateRisk,
  setUpdateCommunicationDetailsPayload,
  cacheCommunicationDetailsFormData,
  setScannedAddressStatus,
  sendUpdateProfileData,
  fetchStates: fetchStates.trigger,
  fetchProfile,
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInCommunicationDetails) as (props: PassedProps) => JSX.Element;
