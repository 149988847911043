import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { ApiError } from '@ac/library-api';

import { getBackgroundTasksErrors } from '@gss/store/backgroundTasks/selectors';
import { getConfigurationErrors } from '@gss/store/configuration/selectors';
import { getMakeKeysProcessErrors } from '@gss/store/flows/makeKeysFlow/selectors';
import { getMainProcessErrors } from '@gss/store/mainProcess/selectors';
import { getSettingsErrors } from '@gss/store/settings/selectors';

interface SubscribedErrorState {
  errors: Array<ApiError | Error>;
  isError: boolean;
}

export const useSubscribedErrorState = (): SubscribedErrorState => {
  const backgroundTasksErrors = useSelector(getBackgroundTasksErrors);
  const configurationErrors = useSelector(getConfigurationErrors);
  const settingsErrors = useSelector(getSettingsErrors);
  const mainProcessErrors = useSelector(getMainProcessErrors);
  const makeKeysProcessErrors = useSelector(getMakeKeysProcessErrors);

  return useMemo((): SubscribedErrorState => {
    const errors = [
      backgroundTasksErrors,
      configurationErrors,
      settingsErrors,
      mainProcessErrors,
      makeKeysProcessErrors,
    ].flat();

    return {
      errors,
      isError: !!errors?.length,
    };
  }, [
    backgroundTasksErrors,
    configurationErrors,
    settingsErrors,
    mainProcessErrors,
    makeKeysProcessErrors,
  ]);
};
