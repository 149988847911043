import { getFactory, postFactory } from '@ac/library-api';

import {
  DocumentScanningResult,
  KioskCheckInUpdateConsents,
  KioskCheckInUpdateProfile,
  RawDocumentScanningResult,
  StartDocumentScanning,
  StateCheckInProcess,
} from '../entries';

const path = {
  startCheckInProcess: '/kiosk/v0/self-service/obsolete/check-in-process/start',
  documentScanningStart:
    '/kiosk/v0/self-service/obsolete/check-in-process/document-scanning/start',
  documentScanningResult:
    '/kiosk/v0/self-service/obsolete/check-in-process/document-scanning/result',
  updateProfile:
    '/kiosk/v0/self-service/obsolete/check-in-process/update-profile',
  updateConsents:
    '/kiosk/v0/self-service/obsolete/check-in-process/update-consents',
};

export const ApiCacheKeyDocumentScanResult =
  'SelfServiceCheckInProcessApiDocumentScanResult';

export const stateCheckInProcess = postFactory<undefined, StateCheckInProcess>(
  path.startCheckInProcess
);

export const documentScanningStart = postFactory<
  undefined,
  StartDocumentScanning
>(path.documentScanningStart);

export const documentScanningResult = getFactory<
  undefined,
  RawDocumentScanningResult,
  DocumentScanningResult
>({
  path: path.documentScanningResult,
  cacheKey: ApiCacheKeyDocumentScanResult,
  ctor: DocumentScanningResult,
});

export const updateProfile = postFactory<undefined, KioskCheckInUpdateProfile>(
  path.updateProfile
);

export const updateConsents = postFactory<
  undefined,
  KioskCheckInUpdateConsents
>(path.updateConsents);
