import { MakeKeysStartProcessDto } from '@gss/api/KioskApi/entries';

export enum MakeKeyAuthenticationSource {
  form = 'form',
  qrCode = 'qrCode',
}

export interface MakeKeyAuthenticationPayload
  extends Omit<MakeKeysStartProcessDto, 'deviceId'> {
  dataSource: MakeKeyAuthenticationSource;
}
