import React, { createRef, PureComponent } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Footer, View } from 'components';
import { ExternalLinkOverlay } from 'components/overlays';
import { compose } from 'redux';
import {
  generateCheckInSummaryPdf,
  updateChangesInProfileConsent,
} from 'store/checkInProcess/actions';
import { CurrentProcessConsent } from 'store/checkInProcess/interfaces';
import { getCurrentProcessConsents } from 'store/checkInProcess/selectors';
import { getErrors, isLoading } from 'store/selectors';
import { ApiError } from 'types/Api/Shared';
import Store from 'types/Store';
import { Configurator } from 'utils';

import {
  Body,
  ExternalHTMLContent,
  ImageMimeTypes,
  Section,
  SignatureField,
} from '@ac/kiosk-components';

import { Header } from '@gss/components/layout';
import { scrollElement, ScrollPosition } from '@gss/utils';

import Consents from './Consents';
import ReservationDetails from './ReservationDetails';

import './CheckInSummary.scss';

const { CHECK_IN_FAILED_MESSAGE } = Configurator.getTranslationCodes();
const BOTTOM_SCROLL_HEIGHT = 0.9;

const BODY_ID = 'body-wrapper-id';
const SIGNATURE_FIELD_ID = 'signature-field-id';

interface PassedProps {}

interface CheckInSummaryProps
  extends PassedProps,
    RouteComponentProps,
    WithTranslation {
  generateCheckInSummaryPdf: typeof generateCheckInSummaryPdf;
  updateChangesInProfileConsent: typeof updateChangesInProfileConsent;
  currentProcessConsents: CurrentProcessConsent[];
  isFetching: boolean;
  errors: ApiError[];
}

interface CheckInSummaryState {
  isScrollEnabled: boolean;
  isLoading: boolean;
  signature?: Blob;
  clickedDisclaimerLink?: string;
}

class CheckInSummary extends PureComponent<
  CheckInSummaryProps,
  CheckInSummaryState
> {
  public static defaultProps = {
    errors: [],
  };

  public state = {
    isScrollEnabled: true,
    isLoading: false,
    signature: undefined,
    clickedDisclaimerLink: undefined,
  };

  public render() {
    const { t, isFetching, errors, currentProcessConsents } = this.props;

    const { signature, isScrollEnabled, isLoading, clickedDisclaimerLink } =
      this.state;

    const isProcessing = isLoading || isFetching;
    const isScrollButtonVisible = isScrollEnabled && !signature;
    const continueButtonLabel = isScrollButtonVisible
      ? t('SCROLL_TO_SIGN')
      : t('CONFIRM');
    const { SHOW_TERMS_AND_CONDITIONS } = Configurator.switchCodes;
    const isTermsAndConditionsEnabled = Configurator.getSwitch(
      SHOW_TERMS_AND_CONDITIONS
    );

    return (
      <View
        idle={{ type: 'modal' }}
        modal={{
          values: errors,
          customErrorCode: CHECK_IN_FAILED_MESSAGE,
          isLoading: isProcessing,
        }}
      >
        {!!clickedDisclaimerLink && (
          <ExternalLinkOverlay
            href={clickedDisclaimerLink}
            onClose={this.handleDisclaimerLinkClose}
          />
        )}

        <Header title={`${t('CHECK_IN')} - ${t('SUMMARY')}`} />
        <Body
          id={BODY_ID}
          className="check-in-summary-body"
          onScroll={this.handleScroll}
        >
          <Section>
            <ReservationDetails />
            {isTermsAndConditionsEnabled && (
              <Consents
                className="spacing-top-xxlg"
                consents={currentProcessConsents}
              />
            )}

            {this.disclaimerContent && (
              <ExternalHTMLContent
                className="spacing-top-xxlg"
                content={this.disclaimerContent}
                onLinkClick={this.handleDisclaimerLinkClick}
              />
            )}

            <div
              id={SIGNATURE_FIELD_ID}
              className="spacing-top-xxlg padding-bottom-sm"
            >
              <SignatureField
                className="check-in-summary-signature"
                signatureImageMimeType={ImageMimeTypes.JPEG}
                label={t('SIGN_TO_CONFIRM')}
                placeholder={t('SIGN_HERE_WITH_YOUR_FINGER')}
                onSignatureCompleted={this.handleSignatureChange}
                onReset={this.handleSignatureChange}
              />
            </div>
          </Section>
        </Body>
        <Footer
          hasBackButton
          hasCancelButton
          hasContinueButton
          isOnContinueFirst={!isScrollButtonVisible}
          goNext
          routeName={t('CHECK_IN')}
          isScrollEnabled={isScrollButtonVisible}
          continueButtonLabel={continueButtonLabel}
          isContinueDisabled={
            isProcessing || (!isScrollButtonVisible && !signature)
          }
          onContinue={this.onContinue}
        />
      </View>
    );
  }

  private get disclaimerContent(): string {
    const { i18n } = this.props;

    return Configurator.getTranslation(
      Configurator.getTranslationCodes()?.SELF_SERVICE_DISCLAIMER,
      i18n.language
    );
  }

  private handleDisclaimerLinkClick = (href: string) => {
    this.setState({ clickedDisclaimerLink: href });
  };

  private handleDisclaimerLinkClose = () => {
    this.setState({ clickedDisclaimerLink: undefined });
  };

  private handleSignatureChange = (signatureImage?: Blob) => {
    this.setState({ signature: signatureImage });
  };

  private generatePdf = async () => {
    const { signature } = this.state;
    const { generateCheckInSummaryPdf } = this.props;

    if (!signature) return;

    return generateCheckInSummaryPdf(signature);
  };

  private scrollToSign = () => {
    scrollElement(
      `#${BODY_ID}`,
      `#${SIGNATURE_FIELD_ID}`,
      ScrollPosition.bottom
    );
  };

  private onContinue = async () => {
    const { isScrollEnabled, signature } = this.state;

    return isScrollEnabled && !signature
      ? this.scrollToSign()
      : this.onSubmit();
  };

  private handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
    const { signature } = this.state;
    const { scrollHeight, offsetHeight, scrollTop } =
      e.target as HTMLDivElement;
    const pageHeight = (scrollTop + offsetHeight) / scrollHeight;
    pageHeight < BOTTOM_SCROLL_HEIGHT
      ? this.setState({ isScrollEnabled: !signature })
      : this.setState({ isScrollEnabled: false });
  };

  private onSubmit = async () => {
    const { signature } = this.state;
    if (!signature) return;

    this.setState({ isLoading: true });
    await this.generatePdf();

    return this.onFormSubmit();
  };

  private onFormSubmit = async () => {
    const { updateChangesInProfileConsent } = this.props;
    await updateChangesInProfileConsent();
    this.setState({ isLoading: false });
  };
}

const mapStateToProps = (state: Store) => ({
  isFetching: isLoading(state),
  errors: getErrors(state),
  currentProcessConsents: getCurrentProcessConsents(state),
});

const mapDispatchToProps = {
  generateCheckInSummaryPdf,
  updateChangesInProfileConsent,
};

export default compose(
  withRouter,
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(CheckInSummary) as (props: PassedProps) => JSX.Element;
