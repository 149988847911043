import {
  DefinitionsPerRolesData,
  HourlyInventorySettingValueData,
  MembershipModuleSettingOptions,
  RawValueOptionsSettingEntity as ValueOptionsSettingEntity,
} from '@ac/library-api';

import { LanguageSettings } from '@gss/api/KioskApi/entries';
import { DEFAULT_APP_LANGUAGE } from '@gss/configs/constants';

import { SettingsCodeMap } from '../../utils';

export interface ExternalSettingsState {
  SUGGESTED_PHONE_PREFIXES?: string[];
  POP_COUNTRY_LIST?: string[];
  DEFAULT_FOLIO_STYLE?: string;
  USE_INSPECTED_STATUS?: boolean;
  DISTRICT?: boolean;
  CORRESPONDENCE_DEFINITIONS?: DefinitionsPerRolesData;
  HOURLY_INVENTORY_MANAGEMENT?: ValueOptionsSettingEntity<HourlyInventorySettingValueData>;
  LANGUAGE: LanguageSettings;
  MEMBERSHIP_MODULE?: ValueOptionsSettingEntity<MembershipModuleSettingOptions>;
}

/**
 * Settings that are not in configuration area designed for SelfService, but comes from different areas.
 * If you will have a problem to find them, go to Configuration > Settings and input in search value of key of setting you are looking for.
 */
export const externalSettingsCodesMap: SettingsCodeMap<ExternalSettingsState> =
  {
    SUGGESTED_PHONE_PREFIXES: {
      key: 'SUGGESTEDPHONEPREFIXES',
    },
    POP_COUNTRY_LIST: {
      key: 'POPCOUNTRYLIST',
    },
    DEFAULT_FOLIO_STYLE: {
      key: 'FOLIOSTYLE',
    },
    USE_INSPECTED_STATUS: {
      key: 'USEINSPECTEDSTATUS',
    },
    CORRESPONDENCE_DEFINITIONS: {
      key: 'CORRESPONDENCEDEFINITIONS',
    },
    HOURLY_INVENTORY_MANAGEMENT: {
      // Requires to turn on feature toggle (HourlyInventory)
      key: 'HOURLYINVENTORYMANAGEMENT',
    },
    DISTRICT: {
      key: 'DISTRICT',
    },
    LANGUAGE: {
      key: 'LANGKIOSK',
      default: {
        languageCode: DEFAULT_APP_LANGUAGE,
        supportedLanguages: [DEFAULT_APP_LANGUAGE],
      },
    },
    MEMBERSHIP_MODULE: {
      key: 'MEMMODULE',
    },
  };
