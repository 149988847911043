import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { PresenceType, useIdleTimer } from 'react-idle-timer';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  ConnectionModal,
  ErrorModal,
  LoadingModal,
  MagicButton,
  MagicButtonHorizontalPosition,
  MagicButtonVerticalPosition,
} from '@ac/kiosk-components';

import {
  SCREEN_SAVER_TIMEOUT,
  SHOW_TECHNICAL_MODAL_LONG_PRESS_TIMEOUT,
} from '@gss/configs/timers';
import { FlowType, useRouter } from '@gss/router';
import {
  rebootApplication,
  resetApplication,
} from '@gss/store/configuration/actions';
import { clearAllGlobalErrors } from '@gss/store/globalActions';
import { setIsAppInactive } from '@gss/store/mainProcess/actions';
import {
  getIsInactiveStatus,
  getIsMainProcessInitialized,
  getIsOfflineStatus,
} from '@gss/store/mainProcess/selectors';
import { getStepsSettings } from '@gss/store/settings/selectors';
import { getErrorMessage } from '@gss/utils/errors/getErrorMessage';
import ScreenSaver from '@LEGACY/components/View/ScreenSaver/ScreenSaver';

import { EnvironmentSettingsModal } from '../modals';

import { useSubscribedErrorState, useSubscribedLoadingState } from './hooks';

import './AppGlobalStateHandler.scss';

export const AppGlobalStateHandler = ({
  children,
}: PropsWithChildren<unknown>): JSX.Element => {
  const isIdleTimerStarted = useRef(false);

  const location = useLocation();
  const router = useRouter();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isServiceModalOpen, setServiceModalOpened] = useState(false);

  const isOffline = useSelector(getIsOfflineStatus);
  const isMainProcessInitialized = useSelector(getIsMainProcessInitialized);
  const isInactive = useSelector(getIsInactiveStatus);
  const stepsSettings = useSelector(getStepsSettings);
  const isLoading = useSubscribedLoadingState();
  const { errors, isError } = useSubscribedErrorState();

  const idleTimer = useIdleTimer({
    startManually: true,
    timeout: SCREEN_SAVER_TIMEOUT,
    onPresenceChange: (presence: PresenceType) => {
      dispatch(setIsAppInactive(presence.type === 'idle'));
    },
  });

  const isSecretButtonAvailable = useMemo(() => {
    return !router?.flowType || router.flowType === FlowType.initial;
  }, [router]);

  const toggleServiceModal = useCallback(() => {
    setServiceModalOpened((prevState) => !prevState);
  }, []);

  const handleGlobalError = useCallback(async (): Promise<void> => {
    if (router?.isCurrentStep('LOGIN')) {
      dispatch(rebootApplication());

      return;
    } else if (!router?.flowType || router.flowType === FlowType.initial) {
      dispatch(resetApplication());
      router?.goTo('LOGIN', { replace: true });

      return;
    } else {
      dispatch(clearAllGlobalErrors());
      router?.goTo('WELCOME', { replace: true });

      return;
    }
  }, [router, dispatch]);

  useEffect(() => {
    if (!isMainProcessInitialized) return;

    if (router?.isCurrentStep('WELCOME')) {
      if (isIdleTimerStarted.current) {
        idleTimer.resume();
      } else {
        idleTimer.start();
        isIdleTimerStarted.current = true;
      }
    } else {
      idleTimer.pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, isMainProcessInitialized]);

  return (
    <>
      {isSecretButtonAvailable && (
        <MagicButton
          dataTestSelector="service-modal-magic-button"
          positionHorizontal={MagicButtonHorizontalPosition.left}
          positionVertical={MagicButtonVerticalPosition.top}
          width={90}
          height={90}
          pressTimeout={SHOW_TECHNICAL_MODAL_LONG_PRESS_TIMEOUT}
          onLongPress={toggleServiceModal}
          className="service-modal-magic-button"
        />
      )}

      {isServiceModalOpen && (
        <EnvironmentSettingsModal
          dataTestSelector="app-state-handler-service-modal"
          onCancelClick={toggleServiceModal}
        />
      )}

      {children}

      {isError && (
        <ErrorModal
          dataTestSelector="app-state-handler-error-modal"
          className="with-default-kiosk-components-theme global-modals-components"
          onConfirm={handleGlobalError}
          description={getErrorMessage(errors, t)}
        />
      )}

      {!isError && isLoading && !(router?.flowType === FlowType.initial) && (
        <LoadingModal
          className="with-default-kiosk-components-theme global-modals-components"
          dataTestSelector="app-state-handler-loading-modal"
        />
      )}

      {isOffline && (
        <ConnectionModal
          className="with-default-kiosk-components-theme connection-modal-component"
          dataTestSelector="app-state-handler-offline-modal"
        />
      )}

      {isInactive && stepsSettings?.SHOW_SCREENSAVER && (
        <ScreenSaver
          dataTestSelector="app-state-handler-screensaver"
          className="screen-saver-element"
        />
      )}
    </>
  );
};
