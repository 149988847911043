import { Action } from 'redux-actions';

import { ApiError } from '@ac/library-api';

import { MakeKeysReservationDetailsDto } from '@gss/api/KioskApi/entries';
import { ReduxReducerMap } from '@gss/types/shared';
import {
  AuthFailedError,
  AuthTooManyReservationsFoundError,
  CustomError,
} from '@gss/utils/errors';

import { handleGlobalFlowReducer } from '../../storeConfiguration/utils';

import * as actions from './actions';
import { MakeKeysAuthenticationStatus, MakeKeysFlowState } from './interfaces';

export const initialState: MakeKeysFlowState = {
  fetching: {
    authentication: false,
    reservationDetails: false,
    closeProcess: false,
  },
  errors: [],
  isMakeKeyProcessReady: false,
  isSecondFactorNeeded: false,
  authenticationStatus: undefined,
  processId: undefined,
  reservationDetails: undefined,
};

const reducerMap: ReduxReducerMap<MakeKeysFlowState> = {
  [actions.authenticateGuest.trigger]: (state): MakeKeysFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        authentication: true,
      },
    };
  },

  [actions.authenticateGuest.success]: (
    state,
    action: Action<string>
  ): MakeKeysFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        authentication: false,
      },
      authenticationStatus: MakeKeysAuthenticationStatus.success,
      processId: action.payload,
    };
  },

  [actions.authenticateGuest.failure]: (
    state,
    action: Action<CustomError & Error>
  ): MakeKeysFlowState => {
    const newState = {
      ...state,
      fetching: {
        ...state.fetching,
        authentication: false,
      },
    };

    if (action.payload.errorName === AuthFailedError.errorName) {
      newState.authenticationStatus = MakeKeysAuthenticationStatus.failed;
    } else if (
      action.payload.errorName === AuthTooManyReservationsFoundError.errorName
    ) {
      if (state.isSecondFactorNeeded) {
        newState.authenticationStatus = MakeKeysAuthenticationStatus.failed;
      } else {
        newState.isSecondFactorNeeded = true;
      }
    } else {
      newState.errors = [...state.errors, action.payload];
    }

    return newState;
  },

  [actions.fetchMakeKeysReservationDetails.trigger]: (
    state
  ): MakeKeysFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        reservationDetails: true,
      },
    };
  },

  [actions.fetchMakeKeysReservationDetails.success]: (
    state,
    action: Action<MakeKeysReservationDetailsDto>
  ): MakeKeysFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        reservationDetails: false,
      },
      reservationDetails: action.payload,
      isMakeKeyProcessReady: true,
    };
  },

  [actions.fetchMakeKeysReservationDetails.failure]: (
    state,
    action: Action<ApiError | Error>
  ): MakeKeysFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        reservationDetails: false,
      },
      errors: [...state.errors, action.payload],
    };
  },

  [actions.closeMakeKeyProcess.trigger]: (state): MakeKeysFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        closeProcess: true,
      },
    };
  },

  [actions.closeMakeKeyProcess.success]: (state): MakeKeysFlowState => {
    return {
      ...state,
      fetching: {
        ...state.fetching,
        closeProcess: false,
      },
    };
  },

  [actions.resetAuthProcess]: (): MakeKeysFlowState => {
    return {
      ...initialState,
    };
  },
};

export const makeKeysFlow = handleGlobalFlowReducer<MakeKeysFlowState>(
  reducerMap,
  initialState
);
