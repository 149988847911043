import i18next from 'i18next';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';

import { KioskComponentsConfigurator } from '@ac/kiosk-components';
import { Action } from '@ac/library-utils/dist/declarations';

import { SelfServiceConfigurationApi } from '@gss/api/KioskApi';
import {
  KioskConfiguration,
  KioskConfigurationProperty,
} from '@gss/api/KioskApi/entries';
import { Authorizer } from '@gss/services';
import { changeAppLanguage } from '@gss/store/configuration/actions';
import { SagasGenerator } from '@gss/types/shared';
import { DateTimeManager } from '@gss/utils/dateTime';
import { fetchLegacySetup } from '@LEGACY/store/setup/actions';

import { ChangeLanguagePayload } from '../configuration/interfaces';
import { getUsedPropertyId } from '../configuration/selectors';

import { PrepareSettingsPayload } from './interfaces/prepareSetupPayload';
import { Settings } from './interfaces/settings';
import { customMessagesCodesMap } from './interfaces/settings/customMessagesState';
import {
  externalSettingsCodesMap,
  ExternalSettingsState,
} from './interfaces/settings/externalSettingsState';
import { generalSettingsCodesMap } from './interfaces/settings/generalSettings';
import { imagesCodesMap } from './interfaces/settings/imagesSettings';
import { stepsSettingsCodesMap } from './interfaces/settings/stepsSettingsState';
import {
  stylesCodesMap,
  StylesSettingsState,
} from './interfaces/settings/stylesSate';
import * as actions from './actions';
import { getPropertyConfiguration, getStyles } from './selectors';
import { mapCustomTheme, mapLayoutSettings, mapProperty } from './utils';

export function* fetchConfiguration(
  payload?: PrepareSettingsPayload
): SagasGenerator<Settings> {
  const withDefaultLanguage = payload?.withDefaultLanguage;

  let configurationResponse: KioskConfiguration =
    yield SelfServiceConfigurationApi.getConfiguration();

  const languageSettings: Pick<ExternalSettingsState, 'LANGUAGE'> | undefined =
    yield mapLayoutSettings(
      { LANGUAGE: externalSettingsCodesMap.LANGUAGE },
      configurationResponse.layoutSettings
    );

  const defaultLanguageCode =
    languageSettings?.LANGUAGE.languageCode.toLowerCase();

  if (
    withDefaultLanguage &&
    defaultLanguageCode &&
    defaultLanguageCode !== i18next.language.toLowerCase()
  ) {
    const propertyId: string = yield select(getUsedPropertyId);

    configurationResponse = yield SelfServiceConfigurationApi.getConfiguration({
      customConfig: Authorizer.getStaticConfiguration({
        propertyId,
        language: defaultLanguageCode,
      }),
    });
  }

  const { property, layoutSettings, ...entities } = configurationResponse;

  const preparedProperty = yield mapProperty(property, layoutSettings);
  const styles = yield mapLayoutSettings(stylesCodesMap, layoutSettings);
  const images = yield mapLayoutSettings(imagesCodesMap, layoutSettings);
  const generalSettings = yield mapLayoutSettings(
    generalSettingsCodesMap,
    layoutSettings
  );
  const stepsSettings = yield mapLayoutSettings(
    stepsSettingsCodesMap,
    layoutSettings
  );
  const externalSettings = yield mapLayoutSettings(
    externalSettingsCodesMap,
    layoutSettings
  );
  const customMessages = yield mapLayoutSettings(
    customMessagesCodesMap,
    layoutSettings
  );

  return {
    property: preparedProperty,
    styles,
    images,
    generalSettings,
    stepsSettings,
    externalSettings,
    customMessages,
    entities,
  };
}

export function* handlePrepareSettings(
  action: Action<PrepareSettingsPayload>
): SagasGenerator {
  try {
    const withDefaultLanguage = action.payload?.withDefaultLanguage ?? true;

    const settings: Settings = yield call(fetchConfiguration, {
      withDefaultLanguage,
    });

    const defaultLanguage =
      settings.externalSettings?.LANGUAGE.languageCode.toLowerCase();

    /**
     * TODO: Remove `fetchLegacySetup` after refactor (Legacy fetchSetup for old code compatibility)
     * Twice yield to await for redux async thunk
     */
    yield yield put(fetchLegacySetup() as unknown as Action);
    yield put(actions.applySettings(settings));

    if (
      withDefaultLanguage &&
      defaultLanguage &&
      defaultLanguage !== i18next.language.toLowerCase()
    ) {
      yield put(
        changeAppLanguage.trigger({
          language: defaultLanguage,
          skipSettingsRefresh: true,
        })
      );
    }

    yield put(actions.prepareSettings.success());
  } catch (error) {
    yield put(actions.prepareSettings.failure(error));
  }
}

export function* handleApplySettings(): SagasGenerator {
  const styles: StylesSettingsState = yield select(getStyles);
  const propertyConfiguration: KioskConfigurationProperty | undefined =
    yield select(getPropertyConfiguration);

  if (propertyConfiguration?.dateTimeFormat) {
    DateTimeManager.saveDateTimeSettings(propertyConfiguration?.dateTimeFormat);
  }

  KioskComponentsConfigurator.createThemeVariables(
    'custom',
    mapCustomTheme(styles)
  );

  KioskComponentsConfigurator.setTheme('custom');
}

export function* handleAppLanguageChange(
  action: Action<ChangeLanguagePayload>
): SagasGenerator {
  if (action.payload.skipSettingsRefresh) {
    return;
  }

  yield put(
    actions.prepareSettings.trigger({
      withDefaultLanguage: false,
    })
  );
}

export function* settingsSagas(): SagasGenerator {
  yield takeLeading(actions.prepareSettings.trigger, handlePrepareSettings);
  yield takeLatest(changeAppLanguage.success, handleAppLanguageChange);
  yield takeLatest(actions.applySettings, handleApplySettings);
}
