import { useCallback, useEffect, useMemo, useState } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';

import { routes } from '../routesConfigurations/routes';
import { FlowType, RouteName, RouterStepCounter, RoutesMap } from '../types';
import { useCurrentFlowType } from '../utils/useCurrentFlowType';
import { useResolveFlowSteps } from '../utils/useResolveFlowSteps';

interface RedirectOptions {
  replace: true;
}

export interface RouterContextValue {
  pathname: string;
  flowType: FlowType | undefined;
  stepCounter: RouterStepCounter | undefined;
  isStepAvailable: (step: RouteName) => boolean;
  getStepPath: (step: RouteName) => string;
  isCurrentStep: (step: RouteName) => boolean;
  disableSteps: (steps: RouteName | RouteName[]) => void;
  enableSteps: (steps: RouteName | RouteName[]) => void;
  goTo: (step: RouteName, options?: RedirectOptions) => void;
  goBack: () => void;
  goToNextStep: (options?: RedirectOptions) => void;
  gotToPreviousStep: (options?: RedirectOptions) => void;
}

export const useRouterContextValue = (): RouterContextValue => {
  const { pathname } = useLocation();
  const history = useHistory();

  const flowType = useCurrentFlowType();
  const [stepCounter, setStepCounter] = useState<RouterStepCounter>();
  const [flowSteps, setFlowSteps] = useState<Partial<RoutesMap>>();

  const resolveSteps = useResolveFlowSteps();

  const enabledFlowSteps = useMemo(() => {
    return flowSteps
      ? Object.values(flowSteps).filter((step) => !step.isDisabled)
      : [];
  }, [flowSteps]);

  const primaryFlowSteps = useMemo(() => {
    return enabledFlowSteps.filter((step) => !step.optionalStep);
  }, [enabledFlowSteps]);

  useEffect(() => {
    setFlowSteps(resolveSteps(flowType));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flowType]);

  useEffect(() => {
    if (!flowType) {
      setStepCounter(undefined);
    } else {
      const stepIndex = enabledFlowSteps.findIndex((step) =>
        matchPath(pathname, { path: step.path })
      );

      const closestStepPathIncludedInCounter = primaryFlowSteps
        .slice(0, stepIndex + 1)
        .reverse()
        .find((step) => !step.excludedFromCounter)?.path;

      const currentStepInCounterIndex = primaryFlowSteps.findIndex(
        (step) => step.path === closestStepPathIncludedInCounter
      );

      setStepCounter({
        stepIndex,
        currentStep: currentStepInCounterIndex + 1,
        totalSteps: primaryFlowSteps.length,
      });
    }
  }, [flowType, pathname, enabledFlowSteps, primaryFlowSteps]);

  const isStepAvailable = useCallback(
    (step: RouteName): boolean => {
      return !flowSteps?.[step]?.isDisabled;
    },
    [flowSteps]
  );

  const getStepPath = useCallback((step: RouteName): string => {
    return routes[step].path;
  }, []);

  const isCurrentStep = useCallback(
    (step: RouteName): boolean => {
      return !!matchPath(pathname, { path: routes[step].path, exact: true });
    },
    [pathname]
  );

  const changeStepAccessibility = useCallback(
    (steps: RouteName | RouteName[], isDisabled: boolean): void => {
      const mappedRoutes: Partial<RoutesMap> = { ...flowSteps };

      if (Array.isArray(steps)) {
        steps.forEach((item) => {
          const step = mappedRoutes?.[item];
          if (step) step.isDisabled = isDisabled;
        });
      } else {
        const step = mappedRoutes?.[steps];
        if (step) step.isDisabled = isDisabled;
      }

      setFlowSteps(mappedRoutes);
    },
    [flowSteps]
  );

  const disableSteps = useCallback(
    (steps: RouteName | RouteName[]): void => {
      changeStepAccessibility(steps, true);
    },
    [changeStepAccessibility]
  );

  const enableSteps = useCallback(
    (steps: RouteName | RouteName[]): void => {
      changeStepAccessibility(steps, false);
    },
    [changeStepAccessibility]
  );

  const goToNextStep = useCallback(
    (options: RedirectOptions = { replace: true }): void => {
      const nextStep = stepCounter?.currentStep
        ? primaryFlowSteps[stepCounter.currentStep]
        : undefined;

      const method = options?.replace
        ? history.replace.bind(history)
        : history.push.bind(history);

      if (nextStep) {
        method(nextStep.path);
      } else {
        history.replace(routes.WELCOME.path);
      }
    },
    [history, primaryFlowSteps, stepCounter]
  );

  const gotToPreviousStep = useCallback(
    (options: RedirectOptions = { replace: true }): void => {
      const prevStep = stepCounter?.currentStep
        ? primaryFlowSteps[stepCounter.currentStep - 1]
        : undefined;

      const method = options?.replace
        ? history.replace.bind(history)
        : history.push.bind(history);

      if (prevStep) {
        method(prevStep.path);
      } else {
        history.replace(routes.WELCOME.path);
      }
    },
    [history, primaryFlowSteps, stepCounter]
  );

  const goBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const goTo = useCallback(
    (step: RouteName, options?: RedirectOptions): void => {
      const path = routes[step].path;
      const method = options?.replace
        ? history.replace.bind(history)
        : history.push.bind(history);

      method(path);
    },
    [history]
  );

  return useMemo<RouterContextValue>(
    () => ({
      pathname,
      flowType,
      stepCounter,
      isStepAvailable,
      getStepPath,
      isCurrentStep,
      disableSteps,
      enableSteps,
      goTo,
      goBack,
      goToNextStep,
      gotToPreviousStep,
    }),
    [
      pathname,
      flowType,
      stepCounter,
      isStepAvailable,
      getStepPath,
      isCurrentStep,
      disableSteps,
      enableSteps,
      goTo,
      goBack,
      goToNextStep,
      gotToPreviousStep,
    ]
  );
};
